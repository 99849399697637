<script lang="ts" setup>
import { currencyFormat } from '@/services/format'
import { getNewWalletStatement, type WalletStatementResult } from '@/services/wallet'
import { type Ref, ref } from 'vue'

const newWalletStatement = ref() as Ref<WalletStatementResult | undefined>
newWalletStatement.value = await getNewWalletStatement(1, 1)
</script>

<template>
  <div
    v-if="newWalletStatement && newWalletStatement.currentBalance > 0"
    class="py-1 px-2 font-semibold leading-normal bg-lime-600 text-white w-full line-clamp-1"
  >
    Você tem
    <span class="whitespace-nowrap">{{ currencyFormat(newWalletStatement.currentBalance) }}</span>
    de crédito em compras
  </div>
</template>
